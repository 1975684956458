// color schemes
$primary: #27a34c;
$primary-color: #27a34c;
$secondary-color: #e5ab3d;
$light: #fff;
$gray: #5e5e5e;
$prepend-gray: #e5e5e5;
$light-gray: #ababab;
$dark-gray: #424242;
$background-gray: #f6f6f6;
$really-light-gray: #919191;
$dark: #212529;
$green: #0daa42;
$success: #a7eeb6;
$danger: #f79797;
$btn-danger: #c13506;
$box-shadow: 0px 0px 9px rgba(107, 127, 153, 0.2);
$gray-rbg: rgba(255, 255, 255, 0.56);
$top-bar-background: #eaeaff;

.sqaure-image {
  overflow: hidden;
  height: 250px;
  /* padding controls height. will always be perfectly sqaure regardless of width */
  padding: 20% 0;
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  & > img {
    width: 150%;
    position: absolute;
    top: -50px;
    left: -80px;
    border-radius: 5px;
  }
}

.link {
  text-decoration: none;
}

.btn-medium {
  padding: 5px 0px;
  font-size: 16px;
  background-color: $primary;
  color: $light;
  border-radius: 10px;
  transition: all 0.5s ease;
  border: 2px solid $primary;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  .loader {
    height: 20px;
    margin-top: 2px;
  }

  // &:hover {
  //   text-decoration: none;
  //   color: $primary;
  //   padding: 6px 21px;
  //   border: 2px solid $primary;
  //   background-color: $light;
  //   font-weight: 500;
  //   transition: all 0.5s ease;
  // }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* margin  */
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 1.5rem 0;
}
.my-3 {
  margin: 2rem 0;
}
.my-4 {
  margin: 3rem 0;
}
.my-5 {
  margin: 4rem 0;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 1.5rem;
}
.mb-3 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 3rem;
}
.mb-5 {
  margin-bottom: 4rem;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 1.5rem;
}
.mt-3 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 3rem;
}
.mt-5 {
  margin-top: 4rem;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 1.5rem;
}
.m-3 {
  margin: 2rem;
}
.m-4 {
  margin: 3rem;
}
.m-5 {
  margin: 4rem;
}

/* padding */

.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 1.5rem 0;
}
.py-3 {
  padding: 2rem 0;
}
.py-4 {
  padding: 3rem 0;
}
.py-5 {
  padding: 4rem 0;
}

.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 1.5rem;
}
.p-3 {
  padding: 2rem;
}
.p-4 {
  padding: 3rem;
}
.p-5 {
  padding: 4rem;
}

//font weight
.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-400 {
  font-weight: 400;
}

//font size
.lead {
  font-size: 18px;
}

.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}

//font-color
.success {
  color: $primary;
}

//display
.display-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.aling-self-center {
  align-self: center;
}
.justify-self-center {
  justify-self: center;
}

//text-color
.text-danger {
  color: red;
}

//input
input[type="file"] {
  display: none;
}

//text-align
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-start {
  text-align: start;
}

//Upload
.upload {
  height: 150px;

  img {
    width: 150px;
    height: 150px;
    display: block;
  }

  .upload-progress {
    font-size: 18px;
    font-weight: bold;
  }
}

//border
.rounded {
  border-radius: 5%;
}

//width
.width-500 {
  width: 500px;
}
.width-400 {
  width: 400px;
}
.width-300 {
  width: 300px;
}
.width-250 {
  width: 250px;
}
.width-200 {
  width: 200px;
}

//background
.bg-primary {
  background-color: $primary-color;
}

.color-light {
  color: $light;
}

@media (max-width: 500px) {
  //display
  .display-grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .width-500 {
    width: 320px;
  }
  .width-400 {
    width: 320px;
  }
}
