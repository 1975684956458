@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "_config";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

.btn .loader {
  width: 20px;
  height: auto;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.card-label {
  right: 5px;
  top: 5px;
  position: absolute;
  height: auto;
  width: 260px;
  background-color: #e7ffdf;
  z-index: 1;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 10fr;
  padding: 2px;

  .icon {
    font-size: 25px;
  }
  p {
    text-align: justify;
    align-self: center;
  }
}

.main-background {
  background-color: #e7ffdf;
  margin-bottom: 0px;
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
}
.main-background::-webkit-scrollbar {
  display: none;
}

.btn {
  cursor: pointer;
  background: $primary;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 45px;
}

.showcase-form {
  position: relative;
  height: auto;
  width: 480px;
  padding: 10px 30px;
  margin: 0px auto;
  overflow: auto;
  scroll-snap-type: none;
}

.formcard {
  position: relative;
  padding: 10px 30px;
  margin: 0px auto;
}

.showcase .showcase-form .copy-section {
  margin-top: 50px;
}

.showcase-form h2,
p {
  text-align: center;
  font-size: 14px;
}
.showcase-form p {
  margin: 40px auto 20px;
  font-size: 14px;
  color: #444444;
}
.showcase-form h2 {
  margin: -15px auto;
}

.form-control {
  margin: 20px 0;
}

.showcase-form img {
  max-width: 127px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
input[type="number"],
input[type="date"] {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  width: 100%;
}

textarea[type="text"] {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 120px;
  padding: 10px;
  resize: none;
  font-size: 14px;
  width: 100%;
}
textarea:focus {
  outline: none;
}
select,
input:focus {
  outline: none;
}

.label-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1px;

  .connect-wallet-btn {
    background-color: $primary;
    color: $light;
    border-radius: 10px;
    padding: 5px 0px;
    margin-left: auto;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    width: 150px;

    img {
      width: 20px;
      height: auto;
    }
  }

  label,
  a {
    font-size: 14px;
    align-self: end;
  }

  a {
    text-align: end;
  }
}

.copy-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  margin: 20px auto;
  padding: 10px;

  p {
    margin: 0;
  }

  div {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;

    input[type="text"] {
      overflow: hidden;
      border-radius: 0px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      height: 35px;
    }

    .copy-button {
      background-color: $primary;
      height: 35px;
      color: $light;
      text-align: center;
      padding-left: 15px;
      font-size: 14px;
      border-radius: 20px;
      margin-left: -20px;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.our-community {
  margin-top: 40px;
  margin-bottom: 20px;
  h3 {
    font-weight: bold;
    margin: 20px auto 10px;
  }
  h2,
  p {
    margin: 0px;
  }

  h2 {
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  p {
    font-size: 14px;
    margin: 10px auto;
  }

  .icon {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 20px auto;

    a {
      align-self: start;
      justify-self: start;
    }

    .react-icon {
      color: #212529;
      font-size: 40px;
    }

    img {
      height: 38px;
      text-align: start;
    }
  }
}

.referral-count {
  margin: 30px 0px;
  h2 {
    font-size: 20px;
    text-align: start;
  }
  a {
    display: block;
  }
}

/* .showcase-form {
  animation: slideinFromLeft 2s ease-in-out;
} */

@keyframes slideinFromLeft {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

/*  Mobile */
@media (max-width: 500px) {
  .main-background {
    background-color: #e7ffdf;
    height: 1200px;
    margin-bottom: 30px;
    overflow-y: scroll; /* Add the ability to scroll */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .showcase-form {
    position: relative;
    height: auto;
    width: 340px;
    padding: 10px 20px;
    margin: 0px auto;
    overflow: auto;
    scroll-snap-type: none;
  }

  .showcase-form input[type="text"],
  .showcase-form input[type="email"] {
    padding: 3px;
    font-size: 14px;
  }

  .label-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1px;

    .connect-wallet-btn {
      background-color: $primary;
      color: $light;
      border-radius: 10px;
      padding: 5px 0px 5px 0px;
      margin-left: none;
      text-align: center;
      width: 150px;
      font-size: 12px;
      cursor: pointer;
      font-size: 12px;
    }

    label,
    a {
      font-size: 12px;
    }

    a {
      text-align: end;
    }
  }

  .copy-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    margin: 20px auto;
    padding: 10px;
  }
}
