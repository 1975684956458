@import "_config";

.admin-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.invested-balance {
  height: 100px;
  width: 400px;
  padding: 20px;
  background-color: $primary-color;
  color: $light;
}

.wallet-balance {
  height: 100px;
  width: 400px;
  padding: 20px;
}

.upload-btn {
  background-color: $primary;
  color: $light;
  border-radius: 10px;
  padding: 5px 0px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  width: 150px;
}

@media (max-width: 500px) {
  .admin-dashboard {
    display: grid;
    grid-template-columns: 1fr;
  }

  .invested-balance {
    height: 100px;
    width: 350px;
    padding: 20px;
    background-color: $primary-color;
    color: $light;
  }

  .wallet-balance {
    height: 100px;
    width: 350px;
    padding: 20px;
  }
}
